import { checkAgentHubStatus, checkAgentHubStatusByAgentToken } from 'actions/AgentHubActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'

const EmptyList: App.AgentHubAgentStatus = {
  fetching: false,
  error: undefined,
  status: '',
  agentId: '',
  action: '',
}

type AgentStatusPropsByToken = {
  agentEmail?: never
  registrationToken: string
}

type AgentStatusPropsByEmail = {
  agentEmail: string
  registrationToken?: never
}

type Props = AgentStatusPropsByToken | AgentStatusPropsByEmail

function useAgentHubAgentStatus({ agentEmail, registrationToken }: Props): App.AgentHubAgentStatus {
  const dispatch = useAppDispatch()

  const agentStatus = useAppSelector(state => state.agentHub.agentStatus[registrationToken ?? agentEmail ?? '']) ?? EmptyList

  useEffect(() => {
    if (registrationToken) {
      dispatch(checkAgentHubStatusByAgentToken(registrationToken))
      return
    }

    if (agentEmail) {
      dispatch(checkAgentHubStatus(agentEmail))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentEmail, registrationToken])

  return agentStatus
}

export default useAgentHubAgentStatus
